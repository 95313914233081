// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */

import React from "react";
import  {  jsx, css, keyframes  }  from  '@emotion/core'
import { chiButton, fonts } from "../style/global";
import FaSortAmountDownAlt from "../misc/fontAwesomeSortDownAlt"
import FaSortAmountDown from "../misc/fontAwesomeSortDown"


export default function OrderButton(props) {

    const { handleChange, order, firstClick } = props
   
// Style for Order Button
    const orderButton = css`
        overflow: hidden;
        vertical-align: middle;

        min-width: 6rem;
        margin-right: 0;
        height: 3.8rem;
        line-height: 3.8rem;

        &:hover {
            transform: scale(1.046);
        }
    `
    const positioning = css`
        transform: translateY(-100%);
    `
    const dispNone = css`
        display: none
    `
    const animateRotateDownOut = keyframes`
        from {transform: translate(0, 0) rotateX(0) translateZ(0);}
        to {transform: translate(0, 100%) rotateX(120deg) translateZ(0);}
    `
    const rotateDownOut = css`
        animation: ${animateRotateDownOut} 0.3s ease-in-out;
        transform: translate(0, 100%) rotateX(120deg) translateZ(0);
    `
    const animateRotateUpOut = keyframes`
        from {transform: translate(0, 0) rotateX(0) translateZ(0);}
        to {transform: translate(0, -100%) rotateX(-120deg) translateZ(0);}
    `
    const rotateUpOut = css`
        animation: ${animateRotateUpOut} 0.3s ease-in-out;
        transform: translate(0, -100%) rotateX(-120deg) translateZ(0);
    `
    const animateRotateDownIn = keyframes`
        from {transform: translate(0, -100%) rotateX(120deg) translateZ(0);}
        to {transform: translate(0, 0) rotateX(0) translateZ(0);}
    `
    const rotateDownIn = css`
        animation: ${animateRotateDownIn} 0.3s ease-in-out;
        transform: translate(0, 0) rotateX(0) translateZ(0);
    `
    const animateRotateUpIn = keyframes`
        from {transform: translate(0, 100%) rotateX(-120deg) translateZ(0);}
        to {transform: translate(0, 0) rotateX(0) translateZ(0);}
    `
    const rotateUpIn = css`
        animation: ${animateRotateUpIn} 0.3s ease-in-out;
        transform: translate(0, 0) rotateX(0) translateZ(0);
    `
// Style for Order Button End

    return (
        <React.Fragment>
            <div onClick={() => {handleChange(!order); firstClick.current = true;}} css={[chiButton, orderButton, fonts.button]}>

                <div css={[(firstClick.current ? (order ? rotateDownIn : rotateUpOut) : null)]}>
                    <span>Order:</span>
                    <FaSortAmountDown />
                </div>

                <div css={[positioning, (firstClick.current ? null : dispNone)]}>
                    <div css={[(firstClick.current ? (order ? rotateDownOut : rotateUpIn) : null)]}>
                        <span>Order:</span>
                        <FaSortAmountDownAlt />
                    </div>
                </div>
                
            </div>
        </React.Fragment>
    )
}