import Fuse from "fuse.js";

export default function fuzzyFilterInspections(params) {
    const { inspections, placeName } = params

    const options = {
        isCaseSensitive: false,
        includeScore: true,
        includeMatches: true,

        keys: [
            "dba_name",
            "aka_name"
        ]
    }

    const fuse = new Fuse(inspections, options)

    const results = fuse.search(placeName)
    return results
}