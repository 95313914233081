import React from 'react'

import InspectionItem from "../components/inspection_components/inspection_item";

export default function createInspectionsList(data) {
    const { inspections, placeName } = data

    const allItems = inspections.map((el, i) => {
        const insp = el.item
        const data = {}
        data.placeName = placeName
        data.inspectionID = insp.inspection_id
        data.inspectionType = insp.inspection_type
        data.inspectionDate = new Date(Date.parse(insp.inspection_date)).toLocaleDateString()
        data.inspectionUTC = Date.parse(insp.inspection_date)
        data.inspectionResult = insp.results
        data.inspectionViolation = insp.violations
        
        const violations = insp.violations
        data.inspectionNumber = violations?.split("|").length
        return <InspectionItem {...data} key={insp.inspection_id} />
    })

    return allItems
}