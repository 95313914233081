// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import React, { useState, useEffect } from "react";
import  { jsx }  from  '@emotion/core'
import postData from "../utility_functions/post_data";
const chiURL = process.env.REACT_APP_URL;

export default function StreetViewImage(props) {
    // Functions that manage state
    const [st_streetViewSource, handleStreetViewSource] = useState(null)

    // Functions to fetch results from Google

    async function getImage(url = '', data = {}) {
        // Default options are marked with *
        const response = await fetch(url, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        const base64Data = await response.text()
        // console.log("here waiting for this thing", base64Data)
        return new Promise(resolve => resolve(base64Data));
    }

    async function fetchStreetViewImage(val) {
        const fnURL = `${chiURL}/.netlify/functions/g_street_view`
        const data = {place_address: val}
        const resp = await getImage(fnURL, data)
        // console.log("here is the street view: ", resp)
        return new Promise(resolve => resolve(resp));
    }

    const imgURL = async function(address) {
        const imgCode = await fetchStreetViewImage(address)
        const imgSRC = `data:image/jpg;base64, ${imgCode}`
        return new Promise(resolve => resolve(imgSRC)) 
        // const imgURL = await URL.createObjectURL(img.blob());
        // console.log("imgURL", imgURL);
        // return imgURL
    }

    useEffect(() => {
        // This conditional statement below checks to see if the image data is already cached in localstorage and
        // saves it to state so the image will be rendered from this stored data
        if (localStorage.getItem('street_view_image') && JSON.parse(localStorage.getItem('street_view_image')).address === props.address) {
            const streetViewCache = JSON.parse(localStorage.getItem('street_view_image'))
            handleStreetViewSource(streetViewCache.image)
        } else {
        // If the street view image key isn't in local storage, OR if the address on the streeview object is different than
        // the current address passed from props, fetch the image from Google.
            (async function() {
                const imageData = await imgURL(props.address)
                const streetViewCache = {
                    address: props.address,
                    image: imageData
                }
                localStorage.setItem('street_view_image', JSON.stringify(streetViewCache))
                return handleStreetViewSource(imageData)
            })()
        }

    }, [props.address])

    return (
        <React.Fragment>
            <img src={st_streetViewSource} alt={`front of ${props.name}`}/>
        </React.Fragment>
    )
}