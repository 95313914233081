// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */

import React, { useState, useRef } from 'react'
import  {  jsx, css, keyframes  }  from  '@emotion/core'
import { colors } from "../style/variables";
import { menuCard } from "../style/global";

import { IconContext } from "react-icons";
import { FiArrowLeft } from "react-icons/fi";
import { FaHistory } from "react-icons/fa";
import { MdBookmarkBorder } from "react-icons/md";
import { Icon, InlineIcon } from '@iconify/react';
import userIcon from '@iconify/icons-gridicons/user';

import menuLogo from '../misc/images/Menu_Logo_200.png';

function Menu() {

    const [st_historyMenu, setHistoryMenu] = useState(false)
    const [st_bookMarkMenu, setBookMarkMenu] = useState(false)
    const [st_profileMenu, setProfileMenu] = useState(false)

    const lastBtnClicked = useRef()
    const storedTarget = useRef()

    const menuControl = {
        "history": setHistoryMenu,
        "bookMark": setBookMarkMenu,
        "profile": setProfileMenu
    }

    function handleButtonClick(e) {
        const target = e.currentTarget
        const icon = target.querySelector("path")
        target.classList.toggle("clicked")
        icon.classList.add("btnActive")
        setTimeout(() => target.classList.toggle("clicked"), 75)
    }

    function handleClick(e, stateArr) {
        const [stateVar, stateHandler] = stateArr

        function fadeActiveBtn() {
            document.getElementById("bottomMenu").querySelector(".btnActive").classList.remove("btnActive")
        }

        if (e.target.classList.contains("menuCard")) {
            return
        }

        if (e.currentTarget.classList.contains("iconFrame")) {
            const isBtnActive = !!document.getElementById("bottomMenu").querySelector(".btnActive")
            if (isBtnActive) {
                fadeActiveBtn()
            }
            handleButtonClick(e)
        }

        const blurLayer = document.getElementById("blurLayer")
        if (stateVar) {
            lastBtnClicked.current = undefined
            blurLayer.classList.toggle("fadeOut")
            blurLayer.classList.toggle("fadeOutCard")
            fadeActiveBtn()
            setTimeout(() => {
                stateHandler(!stateVar)
            }, 350)
        } else {
            if (lastBtnClicked.current) {
                console.log("lastBTN clicked", lastBtnClicked.current)
            }
            if (lastBtnClicked.current !== undefined && e.currentTarget.dataset.btn) {
                storedTarget.current = e.currentTarget
                blurLayer.classList.toggle("fadeOutCard")
                setTimeout(() => {
                    menuControl[lastBtnClicked.current](false)
                    stateHandler(!stateVar)
                    console.log(storedTarget.current)
                    lastBtnClicked.current = storedTarget.current.dataset.btn
                }, 350)
            } else {
                lastBtnClicked.current = e.currentTarget.dataset.btn
                stateHandler(!stateVar)
            }
        }
    }

    const container = css`
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 5.6rem;
        z-index: 5;

        .menuLogo {
            position: absolute;
            left: 50%;
            bottom: calc(50% - 0.2rem);
            transform: translateX(-50%);
            width: 6rem;
            z-index: 6;
        }

        .menuLogoShadow {
            position: absolute;
            left: 50%;
            bottom: calc(50% - 0.1rem);
            transform: translateX(-50%);
            width: 5.8rem;
            height: 5.8rem;
            border-radius: 100%;
            box-shadow: 0 0 0.4rem 0.2rem rgba(0,0,0,0.12), 0 0 0.4rem 0.25rem rgba(0,0,0,0.12);
            z-index: 5;
        }

        .leftShadow {
            position: absolute;
            left: 0;
            top: 0;
            width: calc(50% - 3.5rem);
            height: 0;
            box-shadow: 0 -0.4rem 0.4rem rgba(0,0,0,0.12), 0 0 0.4rem 0.25rem rgba(0,0,0,0.12);
        }

        .rightShadow {
            position: absolute;
            right: 0;
            top: 0;
            width: calc(50% - 3.5rem);
            height: 0;
            box-shadow: 0 -0.4rem 0.4rem rgba(0,0,0,0.12), 0 0 0.4rem 0.25rem rgba(0,0,0,0.12);
        }

        .iconsContainer {
            position: absolute;
            top: 0;
            width: 100%;
            width: calc(50% - 3.6rem);
            height: 100%;
            display: flex;
            z-index: 5;
        }

        .iconBox {
            width: 50%;
            height: 100%;
        }

        .iconFrame {
            width: 5.35rem;
            height: 5.35rem;
            margin: 0.25rem auto;

            svg {
                height: 100%;
                display: block;
                margin: auto;
            }

            path {
                transition: fill 0.33s cubic-bezier(.25,.8,.25,1);
    
                &.btnActive {
                    fill: ${colors.blue};
                }
            }

            &.bookMark {

                path {
                    transition: fill 0.33s cubic-bezier(.25,.8,.25,1), stroke 0.33s cubic-bezier(.25,.8,.25,1);

                    &.btnActive {
                        fill: ${colors.blue};
                        stroke: ${colors.blue};
                    }
                }
        

            }

        }

        .clicked {
            border-radius: 0.4rem 0.4rem 0 0;
            background: ${colors.medGray};
            box-shadow: inset 0 0 0.4rem rgba(0,0,0,0.6);

            svg, .account path {
                transform: translateY(0.2rem);
            }
        }

        .right {
            right: 0;
        }
    `

    const layer = css`
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        z-index: 5;

        &:before {
            content:'';
            position:absolute;
            left: 50%;
            bottom: calc(50% - 0.8rem);
            transform: translateX(-50%);
            width: 7.2rem;
            height: 7.2rem;
            border-radius: 100%;
            box-shadow: 0px 500px 0px 500px ${colors.cardWhite};
        }

        .cutOut {
            position: absolute;
            left: 50%;
            bottom: calc(50% - 0.8rem);
            transform: translateX(-50%);
            width: 7.2rem;
            height: 7.2rem;
            border-radius: 100%;
            box-shadow: inset 0 -0.6rem 0.4rem -0.4rem rgba(0,0,0,0.25);
        }
    `

    const blurInAnimation = keyframes`
        from {
            background: rgba(0, 0, 0, 0);
            backdrop-filter: blur(0);
        }
        to {
            background: rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(0.2rem);
        }
    `
    const blurInCard = keyframes`
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    `
    const blurOutAnimation = keyframes`
        from {
            background: rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(0.2rem);
        }
        to {
            background: rgba(0, 0, 0, 0);
            backdrop-filter: blur(0);
        }
    `
    const blurOutCard = keyframes`
        from {
            opacity: 1;
        }    
        to {
            opacity: 0;
        }
    `

    const blurLayer = css`
        position: fixed;
        top: 0;
        z-index: 4;

        width: 100vw;
        height: 100vh; 

        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(0.2rem);

        .menuCard {
            animation: ${blurInCard} 0.66s ease;
            opacity: 1;
        }

        &.fadeIn {
            animation: ${blurInAnimation} 0.33s ease;
        }

        &.fadeOut {
            animation: ${blurOutAnimation} 0.33s ease;
            background: rgba(0, 0, 0, 0);
            backdrop-filter: blur(0);
        }

        &.fadeOutCard {
            .menuCard {
                animation: ${blurOutCard} 0.33s ease;
                opacity: 0;
            }
        }

        .arrowCut {
            height 100vh;
            width: calc(100vw - 2rem);
        }
    `

    return (
        <React.Fragment>
            <div css={container} id="bottomMenu">
                
                <img src={menuLogo} className="menuLogo" alt="CHI Health Inspector Logo"/>
                <div className="menuLogoShadow"></div>

                <div className="leftShadow"></div>

                <div css={layer}>
                    <div className="cutOut"></div>
                </div>

                <div className="rightShadow"></div>

                <div className="iconsContainer">
                    <div className="iconBox">
                        <div className="iconFrame" onClick={handleButtonClick}>
                            <IconContext.Provider
                                    value={{color: `${colors.menuGray}`, size: "3rem", attr: {"stroke-width": "3"}}}
                                >
                                    <FiArrowLeft />
                            </IconContext.Provider>
                        </div>
                    </div>
                    <div className="iconBox">
                        <div className="iconFrame" data-btn="history" onClick={e => handleClick(e, [st_historyMenu, setHistoryMenu])}>
                            <IconContext.Provider
                                    value={{color: `${colors.menuGray}`, size: "2.6rem"}}
                                >
                                    <FaHistory />
                            </IconContext.Provider>
                        </div>
                    </div>
                </div>
                <div className="iconsContainer right">
                    <div className="iconBox">
                        <div className="iconFrame bookMark" data-btn="bookMark" onClick={e => handleClick(e, [st_bookMarkMenu, setBookMarkMenu])}>
                            <IconContext.Provider
                                    value={{color: `${colors.menuGray}`, size: "3rem", attr: {"stroke-width": "0.6"}}}
                                >
                                    <MdBookmarkBorder />
                            </IconContext.Provider>
                        </div>
                    </div>
                    <div className="iconBox">
                        <div className="iconFrame" data-btn="profile" onClick={e => handleClick(e, [st_profileMenu, setProfileMenu])}>
                            <Icon icon={userIcon} height={"3.2rem"} className={"account"} color={`${colors.menuGray}`} />
                        </div>
                    </div>
                </div>
            </div>

            {st_historyMenu ? 
                <div css={blurLayer} className={lastBtnClicked.current === "history" ? "fadeIn" : null} id="blurLayer" onClick={e => handleClick(e, [st_historyMenu, setHistoryMenu])}>

                    {/* Menu item for recent history */}
                        <div css={[menuCard.cardContainer, menuCard.historyArrow]} className="menuCard">

                        </div>
                    {/* Menu item for recent history end */}

                </div>
            :
                null
            }
            {st_bookMarkMenu ? 
                <div css={blurLayer} className={lastBtnClicked.current === "bookMark" ? "fadeIn" : null} id="blurLayer" onClick={e => handleClick(e, [st_bookMarkMenu, setBookMarkMenu])}>

                    {/* Menu item for bookMark */}
                        <div css={[menuCard.cardContainer, menuCard.bookMarkArrow]} className="menuCard">

                        </div>
                    {/* Menu item for bookMark end */}

                </div>
            :
                null
            }
            {st_profileMenu ? 
                <div css={blurLayer} className={lastBtnClicked.current === "profile" ? "fadeIn" : null} id="blurLayer" onClick={e => handleClick(e, [st_profileMenu, setProfileMenu])}>

                    {/* Menu item for profile */}
                        <div css={[menuCard.cardContainer, menuCard.profileArrow]} className="menuCard">

                            <div css={menuCard.profileTriangle}></div>
                        </div>
                    {/* Menu item for profile end */}

                </div>
            :
                null
            }
        </React.Fragment>
    )
}

export default Menu