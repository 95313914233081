// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import React, { useState } from "react";
import  {  jsx, css, keyframes  }  from  '@emotion/core';
import { IconContext } from "react-icons";
import { colors } from "../style/variables";
import { fonts } from "../style/global";
import { MdChevronRight } from "react-icons/md"
import { Link } from "react-router-dom";


export default function SearchResult(props) {

    // State and hook expressions
    const [st_clickDown, handleClickDown] = useState(null)

    // Styles of the search results box underneath search bar
    const fontColor = colors.blackFont;

    const linkStyle = css`
        text-decoration: none;

        &:visited {
            div {
                color: #609;
            }
        }
    `

    const resultsBox = css`
        display: flex;
        flex-direction: column;
        position: relative;
        top: -2.2rem;
        z-index: 1;
        border: .1rem solid #dfe1ef;
        width: calc(100vw - 2rem);
        margin: 0 auto;
        padding-top: 2.2rem;
        overflow: hidden;

        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

        @media (min-width: 768px) {
            width: calc(50% + 3rem);

        }
    `

    const resultOption = css`
        position: relative;
        display: flex;
        width: 100%;
        height: 3.8rem;
        line-height: 3.8rem;
        border-top: .1rem solid ${colors.borderGray};
        color: ${fontColor};
        font-size: 1.6rem;
        font-family: arial,sans-serif;

        &:hover {
            background-color: ${colors.hoverBlue};
            color: ${colors.blueFont};
            border-top: .1rem solid ${colors.borderBlue};
            cursor: pointer;

            svg {fill: ${colors.blueFont};}
            
            + div {
                border-top: .1rem solid ${colors.borderBlue};
            }

            &:last-child {
                border-bottom: .1rem solid ${colors.borderBlue};
            }
        }
    `

    const resultName = css`
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 50%;
        &:first-of-type {
            padding: 0 1rem 0 1.5rem;
        }
        &:last-of-type {
            padding-right: 3rem;
        }
    `

    const resultClicked = css`
        &:hover {
            background-color: ${colors.medGray};
            color: ${colors.blackFont};
            box-shadow: 0 0 0.4rem rgba(0,0,0,0.6) inset;
            border-color: ${colors.borderGray};
            transform: translateY(0.2rem);

            svg {
                fill: ${colors.blackFont};
            }

            + div {
                border-top: .1rem solid ${colors.borderGray};
            }

            &:last-child {
                border-bottom: .1rem solid ${colors.borderGray};
            }
        }
    `

    const backgroundLight = css`
        background-color: ${colors.white};
    `
    
    const backgroundDark = css`
        background-color: ${colors.lightGray};
    `

    const iconArrow = css`
        fill: #9AA0A6;
        height: 3.8rem;
        position: absolute;
        right: 0.5rem;
        font-size: 2.5rem;
    `

    const animateExpand = keyframes`
        height: calc(100% -2.2rem);
        from {height: 0;}
        to {height: calc(100% - 2.2rem);}
    `

    const resultBoxExpand = css`
        animation: ${animateExpand} .3s ease-in-out;
    `

    const animateRetract = keyframes`
        from {
            height: calc(100% - 2.2rem);
            padding-top: 2.2rem;
        }
        to {
            height: 0;
            padding-top: 0;
        }
    `

    const resultBoxRetract = css`
        height: 0;
        padding-top: 0;
        animation: ${animateRetract} .3s ease-in-out;
    `

    const resultBoxContainer = css`
        height: 21.8rem;
    `

    const resultBoxStates = {
        expand: resultBoxExpand,
        retract: resultBoxRetract
    }

    const resultBoxStateStyle = resultBoxStates[props.resultBoxState]

    // Event functions below

    function handleMouseDown(i) {
        const clickKey = i
        handleClickDown(clickKey);
    }

    // Generate result options

    if (props.resultBoxState === 'closed') {
        return null
    } else {
        const resultOptions = props.autoComplete.map( (x, i) => {
            const name = x.terms[0].value
            const street = x.terms[1].value
            const id = x.place_id
            const background = i % 2 === 0 ? backgroundDark : backgroundLight
            return (
                <Link to={`/${id}`} css={linkStyle} key={i} >
                    <div 
                        css={[resultOption, background, (st_clickDown === i ? resultClicked : null)]} 
                        title={`${name} ${street}`} 
                        data-place-id={id}
                        onClick={() => {handleMouseDown(i); setTimeout(handleMouseDown.bind(null, -1), 75)}}          
                        onMouseDown={() => handleMouseDown(i)}          
                        onTouchStart={() => handleMouseDown(i)}          
                        onMouseUp={() => handleMouseDown(-1)}         
                        onTouchEnd={() => handleMouseDown(-1)}         
                    >
                        <div css={[resultName, fonts.bold]}>{name}</div>
                        {/* <div css={[divider, fonts.bold]}>  </div> */}
                        <div css={[resultName]}>{street}</div>
                        <IconContext.Provider value={{color: "#9AA0A6"}}>    
                            <MdChevronRight css={iconArrow}/>
                        </IconContext.Provider>
                    </div>
                </Link>
                // <div 
                //     css={[resultOption, background, (st_clickDown === i ? resultClicked : null)]} 
                //     key={i} 
                //     title={`${name} ${street}`} 
                //     data-place-id={id}
                //     onClick={() => {handleMouseDown(i); setTimeout(handleMouseDown.bind(null, -1), 75)}}          
                //     onMouseDown={() => handleMouseDown(i)}          
                //     onTouchStart={() => handleMouseDown(i)}          
                //     onMouseUp={() => handleMouseDown(-1)}         
                //     onTouchEnd={() => handleMouseDown(-1)}         
                // >
                //     <div css={[resultName, fonts.bold]}>{name}</div>
                //     {/* <div css={[divider, fonts.bold]}>  </div> */}
                //     <div css={[resultName]}>{street}</div>
                //     <IconContext.Provider value={{color: "#9AA0A6"}}>    
                //         <MdChevronRight css={iconArrow}/>
                //     </IconContext.Provider>
                // </div>
            )
        })
        return (
            <div css={resultBoxContainer}>
                <div css={[resultsBox, resultBoxStateStyle]}>
                    {resultOptions}
                </div>
            </div>
        )
    }
}