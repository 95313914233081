// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */

import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import  {  jsx, css, keyframes  }  from  '@emotion/core'
import { colors } from "../style/variables";
import { fonts, card, page, resultBadge, resultColor } from "../style/global";

import { IconContext } from "react-icons";
import { FaChevronRight } from "react-icons/fa";

import ReportItemContainer from "../components/report_components/report_item_container"

import getReport from "../utility_functions/cdp_get_report"

const Case = require('case')

export default function ReportPage(props) {
    window.scrollTo(0, 0);
    const { placeID, reportID } = useParams()

    const [st_reportData, setReportData] = useState(props.location.info)
    const [st_containerHeight, setContainerHeight] = useState(20)
    
    const reportContainer = useRef()

    let placeName, inspectionType, inspectionDate, inspectionResult, inspectionViolation;
    console.log('st_reportData:', st_reportData)

    console.log('props from ReportPage:', props)
    if (props.location.info) {
        ({ placeName, inspectionType, inspectionDate, inspectionResult, inspectionViolation } = st_reportData);
        console.log("hey I'm in the report data")
        console.log(st_reportData)
    } else if (props.location.info === undefined && st_reportData) {
        placeName = Case.title(st_reportData.dba_name)
        inspectionType = st_reportData.inspection_type
        inspectionDate = new Date(Date.parse(st_reportData.inspection_date)).toLocaleDateString()
        inspectionResult = st_reportData.results
        inspectionViolation = st_reportData.violations
    }

    useEffect(() => {
        if (props.location.info === undefined) {
            (async function() {
                const reportData = await getReport(reportID)
                setReportData(reportData[0])
            })()
        }
    }, [props.location.info])

    // useLayoutEffect(() => {
    //     if (st_reportData) {            
    //         console.log(reportContainer.current.getBoundingClientRect());
    //         const height = reportContainer.current.getBoundingClientRect().height
    //         setContainerHeight((height/10))
    //     }
    // }, [st_reportData])


    // Style expressions \/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/

        // Style for header section
        const block = css`
            display: inline-block;
        `
        const relative = css`
            position: relative;
        `
        const headContainer = css`
            width: 100vw;
            margin-top: 3rem;
        `
        const reportHead = css`
            margin: 3rem auto 0 1rem;
            width: calc(100vw - 10rem);
            box-shadow: inset 0px -1px 0px ${colors.cardBorder}, 0 0.4rem 0.4rem rgba(0,0,0,0.25), 0 0.3rem 0.6rem rgba(0,0,0,0.12);
            border-radius: 0.4rem;
            background: ${colors.cardBody};
            height: 8rem;
            border-radius: 0.4rem 0 0 0;
            background: ${colors.cardWhite};
            display: inline-block;
            z-index: 1;
            vertical-align: top;
            position: relative;
        `
        const shadowContainer = css`
            position: relative;
            height: 0;
            width: 100%;
        `
        const shadowTransition = css`
            position: absolute;
            height: 0.5rem;
            width: 1rem;
            left: -1rem;
            z-index: 3;
            box-shadow: 0.2rem -0.2rem 0.2rem -0.19rem rgba(0,0,0,0.12);
        `
        const shadowTransitionR = css`
            position: absolute;
            height: 1rem;
            width: 0.5rem;
            right: 0;
            bottom: -1rem;
            z-index: 3;
            box-shadow: 0.2rem -0.2rem 0.2rem -0.19rem rgba(0,0,0,0.12);
        `
        // const shadowTransition = css`
        //     position: absolute;
        //     background: red;
        //     height: 0.5rem;
        //     width: 0.5rem;
        //     left: -0.5rem;
        //     z-index: 4;
        //     border-radius: 0.4rem 0.4rem 0 0;
        //     box-shadow: 0.4rem -0.4rem 0.4rem rgba(0,0,0,0.25);
        // `
        const topBox = css`
            position: absolute;
            background: ${colors.cardBorder};
            height: 1rem;
            width: 8.75rem;
            left: -0.7rem;
            top: -1rem;
            z-index: 3;
        `
        const rightBox = css`
            position: absolute;
            background: ${colors.cardBorder};
            height: 8.75rem;
            width: 1rem;
            right: -1rem;
            bottom: -0.04rem;
            z-index: 3;
            border-radius: 0 0 0 0.06rem;
        `
        const headContent = css`
            padding: 1.5rem 1.5rem;
            width: calc(100vw - 5rem);
        `
        const titleLine = css`
            display: inline-grid;
            grid-template-rows: [row-1] 2.8rem;
            grid-template-columns: [col-title] auto [col-badge] auto [col-dogEar] 8rem;
            grid-template-areas:
                "title badge dogEar";
            align-items: center;


            h1 {
                grid-area: title;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .badge {
                grid-area: badge;
                width: auto;
                line-height: 1.8rem;
            }
        `
        const dogEarContainer = css`
            margin: 3rem 0 0;
            display: inline-block;
            position: relative;
        `
        const dogEar = css`
            height: 8rem;
            width: 8rem;
            background-image: linear-gradient(to top right, ${colors.cardWhite}, ${colors.cardWhite} 50%, rgba(0,0,0,0) 50%);
            box-shadow: 0 0.4rem 0.4rem rgba(0,0,0,0.12), -0.4rem 0 0.4rem rgba(0,0,0,0.12);
            z-index: 2;
            border-radius: 0 0 0 0.4rem;
        `
        // const dogEar = css`
        //     height: 8rem;
        //     width: 8rem;
        //     background-image: linear-gradient(to top right, ${colors.cardWhite}, ${colors.cardWhite} 50%, rgba(0,0,0,0) 50%);
        //     box-shadow: 1rem 0 0 ${colors.cardBorder}, 0 -1rem 0 ${colors.cardBorder}, 0 0.4rem 0.4rem rgba(0,0,0,0.12), -0.4rem 0 0.4rem rgba(0,0,0,0.12);
        //     z-index: 2;
        //     border-radius: 0 0 0 0.4rem;
        // `
        const diagonalShadow = css`
            background: rgba(0,0,0,0);
            width: 11.314rem;
            height: 1rem;
            position: absolute;
            transform: translate(-2rem, -4.15rem) rotate(45deg);
            z-index: 4;
            box-shadow: 0 -0.4rem 0.4rem -0.4rem rgba(0,0,0,0.2);
        `
        const cornerBackground = css`
            width: 1rem;
            height: 1rem;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            background: ${colors.cardWhite};
            box-shadow: inset 0px -1px 0px ${colors.cardBorder};
        `
        // const dogEar = css`
        //     height: 8rem;
        //     width: 8rem;
        //     background-image: linear-gradient(to top right, ${colors.cardWhite}, ${colors.cardWhite} 50%, rgba(0,0,0,0) 50%);
        //     box-shadow: 0.4rem 0 0 -0.4rem ${colors.cardBorder}, 0 -0.4rem 0 -0.4rem ${colors.cardBorder}, 0 0.4rem 0.4rem rgba(0,0,0,0.12), -0.4rem 0 0.4rem rgba(0,0,0,0.12);
        //     z-index: 2;
        //     border-radius: 0 0 0 0.4rem;
        // `
        // const dogEar = css`
        //     display: inline-block;
        //     height: 8rem;
        //     width: 8rem;
        //     margin-top: 3rem;
        //     background-image: linear-gradient(to top right, ${colors.cardWhite}, ${colors.cardWhite} 50%, rgba(0,0,0,0) 50%);
        //     box-shadow: -0.4rem 0.4rem 0.4rem rgba(0,0,0,0.25);
        //     z-index: 1;
        //     border-radius: 0 0 0 0.4rem;
        // `
        const dogEarRemove = css`
            width: 0;
            height: 0;
            border-left: 8rem solid transparent;
            border-top: 8rem solid ${colors.cardBorder};
            position: absolute;
            top: 0;
            right: 0;
        `
        // const dogEar = css`
        //     width: 0;
        //     height: 0;
        //     border-right: 8rem solid transparent;
        //     border-bottom: 8rem solid red;
        //     position: absolute;
        //     top: 0;
        //     right: -8rem;
        //     box-shadow: 0 0.4rem 0.4rem rgba(0,0,0,0.25), 0 0.3rem 0.6rem rgba(0,0,0,0.12);
        // `
        const reportBody = css`
            width: calc(100vw - 2rem);
            margin: -0.2rem auto 10rem;
            background: ${colors.cardWhite};
            border-radius: 0 0 0.4rem 0.4rem;
            box-shadow: 0 0.4rem 0.4rem rgba(0,0,0,0.25), 0 0.3rem 0.6rem rgba(0,0,0,0.12);
            z-index: 1;
        `
        // const reportBody = css`
        //     width: calc(100vw - 2rem);
        //     height: ${st_containerHeight};
        //     margin: -0.2rem auto 3rem;
        //     background: ${colors.cardWhite};
        //     border-radius: 0 0 0.4rem 0.4rem;
        //     box-shadow: 0 0.4rem 0.4rem rgba(0,0,0,0.25), 0 0.3rem 0.6rem rgba(0,0,0,0.12);
        //     z-index: 1;
        // `
        // const dogEar = css`
        //     width: 0;
        //     height: 0;
        //     border-right: 8rem solid transparent;
        //     border-bottom: 8rem solid ${colors.cardWhite};
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        // `

        const reportItem = css`
            min-height: 3rem;
            padding: 1.5rem 1.5rem;
            box-shadow: inset 0px -1px 0px ${colors.cardBorder};

            &:last-of-type {
                box-shadow: none;
            }

            .itemDetails {
                padding: 1rem 0 0 2.1rem;
                font-family: 'Roboto',arial,sans-serif;
                font-size: 1.3rem;
                line-height: 1.5rem;
                color: #3C4257;
            }
        `
        const itemTitle = css`
            width: calc(100vw - 5rem);
            display: flex;
            align-items: center;

            svg {
                font-size: 1.8rem;
                margin-right: 1rem;
            }

            .titleText {
                font-weight: 500;
                line-height: 2rem;
            }
        `

        

    // /\/\/\/\/\/\/\/\//\/\/\/\//\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\


    return (
        <div css={page.container}>
            {st_reportData ? 
            
            // <div css={[cardContainer, relative, fonts.regular]}>
            //     <div css={[cardHead, relative]}>
            //         <div css={verticalSpacer}>
            //             <div>
            //                 <h1 css={[fonts.cardTitle, block]}>{placeName}</h1>
            //                 <div css={[resultBadge, {marginLeft: "1rem", background: resultColor[inspectionResult]}]}>{inspectionResult}</div>
            //             </div>
            //             <h3 css={fonts.cardSubTitle}>{inspectionType}: {inspectionDate}</h3>
            //             <div css={dogEarRemove}></div>
            //             <div css={dogEar}></div>
            //         </div>
            //     </div>
            // </div>
            <React.Fragment>
                <div css={[reportHead, relative]}>
                    {/* <div className="shadow-container" css={shadowContainer}>
                        <div css={shadowTransition}></div>
                    </div> */}
                    <div css={headContent}>
                        <div css={titleLine}>
                            <h1 css={[fonts.cardTitle, block]} title={placeName}>{placeName}</h1>
                            <div className="badge" css={[resultBadge, {marginLeft: "1rem", background: resultColor[inspectionResult]}]}>{inspectionResult}</div>
                        </div>
                        <h3 css={[fonts.cardSubTitle, {display: "block"}]}>{inspectionType}: {inspectionDate}</h3>
                    </div>
                </div>
                <div css={dogEarContainer}>
                    <div css={topBox}></div>
                    <div css={rightBox}></div>
                    <div css={shadowTransition}></div>
                    <div css={shadowTransitionR}></div>
                    <div css={[dogEar, relative]}></div>
                    <div css={diagonalShadow}></div>
                    <div css={cornerBackground}></div>
                </div>
                <div ref={reportContainer} css={[reportBody, relative]}>
                    {/* <div css={reportItem}>
                        <div css={itemTitle}>
                            <IconContext.Provider value={{color: `${colors.blueFont}`, size: "2.4rem"}}>
                                <FaChevronRight css={{marginLeft: "0.2rem"}} />
                            </IconContext.Provider>
                            <div className="titleText" css={fonts.regular}>This is the title of this item and stuff. Sometimes it may be long and not fit on the page because they have a lot to report</div>
                        </div>
                    </div>
                    <div css={reportItem}>
                        <div css={itemTitle}>
                            <IconContext.Provider value={{color: `${colors.blueFont}`, size: "2.4rem"}}>
                                <FaChevronRight css={{marginLeft: "0.2rem"}} />
                            </IconContext.Provider>
                            <div className="titleText" css={fonts.regular}>This is the title of this item and stuff. Sometimes it may be long and not fit on the page because they have a lot to report</div>
                        </div>
                        <div className="itemDetails">
                            NOTED ABOUT 50 FLYING INSECTS ON WALLS AND CEILING OF THE MAIN BAR AT THE FIRST FLOOR. INSTRUCTED TO CLEAN AND SANITIZE AFFECTED AREAS. RECOMMENDED TO CONSULT WITH PEST CONTROL COMPANY.  CITATION ISSUED FOR SERIOUS VIOLATION #7-38-020
                        </div>
                    </div> */}
                    <ReportItemContainer violations={inspectionViolation} />
                </div>
            </React.Fragment>


            :

            null

            }


        </div>
    )
}