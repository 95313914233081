export function sortChronological(inspections) {
    const sorted = inspections.sort((a, b) => {
        return b.props.inspectionUTC - a.props.inspectionUTC
    })
    return sorted
}

const resultScores = {
    "Pass": 6,
    "Pass w/ Conditions": 5,
    "No Entry": 4,
    "Not Ready": 3,
    "Out of Business": 2,
    "Fail": 1,
}

export function sortResult(inspections) {
    const sorted = inspections.sort((a, b) => {
        if (a.props.inspectionResult === b.props.inspectionResult) {
            return b.props.inspectionUTC - a.props.inspectionUTC
        } else {
            return resultScores[a.props.inspectionResult] - resultScores[b.props.inspectionResult]
        }
    })
    return sorted
}

export function sortViolations(inspections) {
    // console.log(inspections)
    const sorted = inspections.sort((a, b) => {
        let current = a.props.inspectionNumber
        let next = b.props.inspectionNumber
        if (current === undefined) {
            current = 0
        }
        if (next === undefined) {
            next = 0
        }

        if (a.props.inspectionNumber === b.props.inspectionNumber) {
            return b.props.inspectionUTC - a.props.inspectionUTC
        } else {
            return next - current
        }
    })
    return sorted
}