// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */

import React, { useRef, useEffect } from "react";
import  {  jsx, css  }  from  '@emotion/core'

import SearchBar from '../components/search_bar'

export default function HomePage() {

    const logoIMG = useRef()

    const page = css`
        width: 100vw;
        height: 100vh;
    `

    // Styling for app logo
    const logoContainer = css`
        width: 26rem;
        height: 26rem;
        margin: 2.5rem auto 0;
        opacity: 1;
        transition: opacity 1s ease-out, height 1s ease-out, margin 1s ease-out;

        &.faded {
            opacity: 0;
            height: 0;
            margin: 0 auto;
        }

        img, picture {
            max-width: 100%;
            height: auto;
        }
    `

    return (
        <div css={page} id="page">
            <div ref={logoIMG} css={logoContainer}>
                <picture>
                    <source 
                        srcSet="https://d1h14d4mdjmxpz.cloudfront.net/chi-logo/3d/CHI-3d-logo@780.png" 
                        type="image/png"
                    />
                    <img src="https://d1h14d4mdjmxpz.cloudfront.net/chi-logo/3d/CHI-3d-logo@780.png" alt="Chicago Health Inspector logo" />
                </picture>
            </div>
    
            <SearchBar logoRef={logoIMG}></SearchBar>
        </div>
    )
}