import citationRef from './citation_reference'
const Case = require('case')

export default function parseReport(data) {
    const newStr = data.split("|")

    function getCodeUrl(num) {
        const url = "https://webapps1.chicago.gov/healthinspection/Code_Violations.jsp#"
        if (num < 15) {
            return `${url}critical`
        } else if (num < 30 ) {
            return `${url}serious`
        } else {
            return `${url}minor`
        }
    }

    const violationsArr = newStr.map(el => {
        let [title, details] = el.split("- Comments:")

        title = title.trim()
        details = details.trim()

        title = Case.sentence(title, null)
        details = Case.sentence(details, null)
        
        details = details.replace(/\df/gi, (match, sub, offset, str) => {
            const [num, ] = match.split("")
            return `${num}℉`
        })
        details = details.replace(/\sdegrees\sf/i, "℉")
        details = details.replace(/critical/i, "CRITICAL")
        details = details.replace(/serious/i, "SERIOUS")
        details = details.replace(/minor/i, "MINOR")
        details = details.replace(/priority/i, "PRIORITY")
        details = details.replace(/foundation/i, "FOUNDATION")
        details = details.replace(/violation/i, "VIOLATION")
        details = details.replace(/citation\sissued/i, "CITATION ISSUED")
        details = details.replace(/core/i, "CORE")
        details = details.replace(/corrected\son\ssite/i, "CORRECTED ON SITE")
        details = details.replace(/tcs/i, "TCS")

        const code = title.match(/^\d+./)
        const codeNum = parseInt(code[0].slice(0, -1))

        title = title.replace(code[0], `<a href="${getCodeUrl(codeNum)}" target="_blank">${code[0]}</a>`)

        const citation = details.match(/7-38-\d{3}/)

        if (citation) {
            console.log("test")
            console.log("test", citation)
        
            details = details.replace(citation[0], `<a href="${citationRef[citation[0]]}" target="_blank">${citation[0]}</a>`)
        }
        
        return {
            title: title,
            details: details
        }
    })

    return violationsArr
}