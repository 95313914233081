// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */

import React, { useState, useRef, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import  {  jsx, css, keyframes  }  from  '@emotion/core';
import { IconContext } from "react-icons";
import { MdBookmark } from "react-icons/md";
import { IoMdDocument } from "react-icons/io";

import { Icon, InlineIcon } from '@iconify/react';
import documentOutline from '@iconify/icons-ion/document-outline';


import { fonts, card, resultBadge, resultColor } from '../../style/global';
import { colors } from "../../style/variables";

export default function InspectionItem(props) {

    const {
        inspectionType,
        inspectionDate,
        inspectionResult,
        inspectionNumber,
        inspectionID,
        inspectionViolation,
        placeName
    } = props
    const { placeID } = useParams()

    const typeBox = useRef(null)

    function handleCrawl(e) {
        e.preventDefault()
        e.stopPropagation()

        typeBox.current.classList.add("inspect-text-crawl")
        setTimeout(() => {
            typeBox.current.classList.remove("inspect-text-crawl")
        }, 5010)
    }

    function stopClick(e) {
        e.preventDefault()
    }

    const [st_textWidth, setTextWidth] = useState(null)

    useEffect(() => {
        setTextWidth((typeBox.current.scrollWidth)/10)
    })

    const container = css`
        width: 101%;
        height: 7.7rem;
        display: flex;
        justify-content: center;
    `

    const box = css`
        width: 99%;
        height: 6.2rem;
        background: ${colors.cardWhite};
        color: ${colors.grayFont};
        text-decoration: none;
        font-size: 1.4rem;
        border-radius: 0.4rem;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(60, 66, 87, 0.23) 0px 2px 5px 0px;
        transition: box-shadow 0.33s ease, width 0.33s ease, height 0.33s ease;

        &:hover {
            box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(60, 66, 87, 0.23) 0px 2px 5px 0px, rgba(60, 66, 87, 0.23) 0px 3px 9px 0px;
            cursor: pointer;
            width: 100%;
            height: 6.3rem;
        }

        display: grid;
        grid-template-rows: [row-1] 100%;
        grid-template-columns: [col-bookMark] 4.4rem [col-left] 9.5rem [col-right] auto [col-doc] 5.5rem;
        grid-template-areas:
            "bookmark left right doc";

        &.disabled {
            opacity: 0.75;

            &:hover {
                cursor: not-allowed;
                width: 99%;
                height: 6.2rem;
            }
        }
    `
    const center = css`
        justify-self: center;
        align-self: center;
    `
    const left = css`
        justify-self: start;
        align-self: center;
    `
    const bookmarkContainer = css`
        grid-area: bookmark;

        svg {
            stroke: ${colors.grayFont};
            stroke-width: 0.2rem;
            fill: ${colors.cardWhite};
            transition: fill 0.33s ease;

            &:hover {
                fill: rgba(60,66,87,0.25)
            }
        }
    `
    const padding = css`
        padding: 0 1.5;
    `
    const leftInfo = css`
        grid-area: left;
    `
    const st_inspectionType = css`
        font-weight: 500;
        line-height: 2rem;
    `
    const animateTextCrawl = keyframes`
        0% {transform: translate(0, 0);}
        50% {transform: translate(-${st_textWidth - 8.25}rem, 0);}
        100% {transform: translate(0, 0);}
    `
    const st_textOverflow = css`
        position: relative;
        overflow: hidden;
        z-index: 0;

        .inspect-text {
            position: relative;
            display: inline-block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            z-index: 1;
            max-width: 8.75rem;
            line-height: 2rem;
        }

        .inspect-text-crawl {
            overflow: visible;
            margin-bottom: 0.5rem;
            animation: ${animateTextCrawl} 5s ease-in-out;
        }
    `
    // const st_textOverflow = css`
    //     overflow: hidden;
    //     position: relative;
    //     z-index: 2;

    //     .inspect-text {
    //         position: relative;
    //         display: inline-block;
    //         white-space: nowrap;
    //         overflow: hidden;
    //         text-overflow: ellipsis;
    //         z-index: 1;
    //         max-width: 8.75rem;
    //     }

    //     .inspect-text-crawl {
    //         text-overflow: none;
    //         animation: ${animateTextCrawl} 5s ease-in-out;
    //     }
    // `
    const st_inspectionDate = css`
        line-height: 2rem;
        font-weight: normal;
    `
    const rightInfo = css`
        grid-area: right;
    `
    const st_inspectionItems = css`
        line-height: 2rem;
    `
    const docArea = css`
        grid-area: doc;

        svg {
            height: 4rem;
            stroke: ${colors.grayFont};
            fill: ${colors.cardWhite};
        }

        path {
            stroke-width: 26 !important;
        }
    `
    const docNumber = css`
        grid-area: doc;
        font-family: 'Nunito';
        font-weight: 600;
        font-size: 1.8rem;
        position: relative;
        top: 7%;
        left: -0.05rem;
    `
    return (
        <div css={container}>
            <Link 
                to={{
                    pathname: `/${placeID}/${inspectionID}`,
                    info: props
                }} 
                css={[box, fonts.regular]}
                className={inspectionNumber === undefined ? "disabled" : null}
                onClick={inspectionNumber === undefined ? stopClick : null}
            >
                <div css={[bookmarkContainer, center]}>
                    <IconContext.Provider value={{color: `${colors.grayFont}`, size: "2.4rem"}}>
                        <MdBookmark />
                    </IconContext.Provider>
                </div>
                <div css={[leftInfo, left, padding]}>
                    <div 
                    css={[st_inspectionType, (inspectionType.length > 12 ? st_textOverflow : null)]}
                    onClick={handleCrawl}
                    >
                        <div ref={typeBox} className="inspect-text">
                            {inspectionType}
                        </div>
                    </div>
                    <div css={st_inspectionDate}>{inspectionDate}</div>
                </div>
                <div css={[rightInfo, left, padding]}>
                    <div css={[resultBadge, {background: resultColor[inspectionResult]}, (inspectionResult === "Out of Business" ? {color: colors.cardWhite} : null) ]}>{inspectionResult}</div>
                    <div css={st_inspectionItems}>
                        {inspectionNumber === undefined ?
                            "No Violations Found"
                            :
                            inspectionNumber > 1 ? 
                                `${inspectionNumber} Violations Found`
                                :
                                `${inspectionNumber} Violation Found`
                        }
                    </div>
                </div>
                <div css={[docArea, center]}>
                    <Icon icon={documentOutline} width={"4rem"} color={colors.grayFont} />
                </div>
                <div css={[docNumber, center]}>
                    {inspectionNumber === undefined ? "X" : inspectionNumber}
                </div>
            </Link>
        </div>
    )
}