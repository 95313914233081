import React from 'react';
import Select from 'react-select';
import { colors as chiColors } from '../style/variables';
import { fonts as chiFonts } from '../style/global';

export default function ChiSelect(props) {
    const { options, handleChange } = props 

    const shadowInactive = "rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(60, 66, 87, 0.23) 0px 2px 5px 0px;"
    const shadowActive = "rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(60, 66, 87, 0.23) 0px 2px 5px 0px, rgba(60, 66, 87, 0.23) 0px 3px 9px 0px;"
    // const shadowInactive = "rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.12) 0px 2px 5px 0px;"
    // const shadowActive = "rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(60, 66, 87, 0.12) 0px 3px 9px 0px, rgba(60, 66, 87, 0.12) 0px 2px 5px 0px;"

    const customStyles = {
        control: (styles, {isFocused}) => ({
            ...styles,
            fontFamily: chiFonts.regular,
            fontWeight: 500,
            color: chiColors.grayFont,
            borderRadius: '0.4rem',
            fontSize: "1.4rem",
            lineHeight: "2rem",
            boxShadow: isFocused ? shadowActive : shadowInactive,
            border: "none",
            transition: "box-shadow 0.33s ease",

            '&:hover': {
                boxShadow: shadowActive,
                transform: "scale(1.01)",
                cursor: "pointer",
            },
            ':before': {
                content: '"Sort:"',
                fontSize: "1.4rem",
                paddingLeft: "0.8rem",
                paddingRight: "0.2rem",
                fontFamily: chiFonts.regular
            }
        }),
        menu: (styles) => ({
            ...styles,
            width: window.innerWidth < 415 ? "14.5rem" : "100%"
        }),
        valueContainer: (styles) => ({
            ...styles,
            padding: "0.2rem 0.8rem 0.2rem 0"
        }),
        placeholder: (styles) => ({
            ...styles,
            color: chiColors.grayFont
        }),
        indicatorSeparator: () => ({
            display: "none"
        }),
        option: (styles, state) => ({
            ...styles,
            fontFamily: chiFonts.regular,
            backgroundColor: state.isFocused ? chiColors.cardBody : chiColors.white,
            color: chiColors.grayFont,
            fontWeight: state.isSelected ? 500 : 400,
            padding: "0.8rem",
            fontSize: "1.4rem",

            ':active': {
                backgroundColor: "#B2D4FF"
            },
            ':before': {
                content: state.isSelected ? '"• "' : '""',
                fontSize: "1.6rem"
            }
        })
    }

    return (
        <Select onChange={handleChange} styles={customStyles} options={options} defaultValue={options[0]} isSearchable={false}/>
    )
}