// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */

import React, {useRef} from 'react'
import  {  jsx, css, keyframes  }  from  '@emotion/core'
import { fonts, card } from '../../style/global'
import { colors } from "../../style/variables";

import { IconContext } from "react-icons";
import { FaChevronRight } from "react-icons/fa";

function ReportItem(props) {

    const { title, details } = props.data

    const reportContainer = useRef()
    const reportTitle = useRef()
    const reportDetails = useRef()

    function expandItem() {
        console.log("selecting by classname" , reportContainer.current.getElementsByClassName("itemDetails"))
        reportContainer.current.classList.toggle("expanded")

        const arrow = reportContainer.current.getElementsByClassName("arrow")[0]
        arrow.classList.toggle("down")

        const content = reportContainer.current.getElementsByClassName("itemDetails")[0]
        content.classList.toggle("expanded")
        if (content.style.maxHeight) {
            content.style.maxHeight = null;
        } else {
            content.style.maxHeight = content.scrollHeight + "px";
        }
        // debugger;
    }

    // 0.33s cubic-bezier(.25,.8,.25,1)

    const reportItem = css`
        min-height: 3rem;
        padding: 1.5rem 1.5rem;
        box-shadow: inset 0px -1px 0px ${colors.cardBorder};
        background: ${colors.cardWhite};

        transition: background 0.33s cubic-bezier(.25,.8,.25,1);

        &.expanded {
            background: ${colors.cardBody};

            .titleText {
                color: ${colors.grayFont};
            }
            
            .titleText:hover {
                color: ${colors.darkFont};
            }

        }

        &:last-of-type {
            box-shadow: none;
            border-radius: 0 0 0.4rem 0.4rem;
        }

        .arrow {
            transform: rotate(0deg);
            transition: transform 0.33s ease-out, color 0.33s cubic-bezier(.25,.8,.25,1);
        }

        .arrow.down {
            transform: rotate(90deg);
            color: ${colors.grayFont} !important;
        }

        .itemDetails {
            padding-top: 0;
            padding-left: 3.2rem;
            font-family: 'Roboto',arial,sans-serif;
            font-size: 1.3rem;
            line-height: 2rem;
            color: #3C4257;
            overflow: hidden;
            max-height: 0;

            transition: max-height 0.33s ease-out, padding-top 0.33s ease-out;
        }

        .itemDetails.expanded {
            padding-top: 1rem;
        }
    `
    const itemTitle = css`
        width: calc(100vw - 5rem);
        display: flex;
        align-items: center;
        cursor: pointer;
        min-height: 3rem;

        svg {
            font-size: 1.8rem;
            margin-right: 1rem;
        }

        .titleText {
            font-weight: 500;
            line-height: 2rem;
            width: 100%;
            color: ${colors.blueFont};
            transition: color 0.33s cubic-bezier(.25,.8,.25,1);

            &:hover {
                color: ${colors.darkBlueFont};
            }
        }
    `

    return (
        <div ref={reportContainer} css={reportItem}>
            <div ref={reportTitle} css={itemTitle} onClick={expandItem}>
                <IconContext.Provider
                    value={{color: `${colors.blueFont}`, size: "2.4rem", className: "arrow"}}
                >
                        <FaChevronRight />
                </IconContext.Provider>
                <div className="titleText" css={fonts.regular} dangerouslySetInnerHTML={{ __html: title }} />
            </div>
            <div ref={reportDetails} className="itemDetails" dangerouslySetInnerHTML={{ __html: details }} />
        </div>
    )
}

export default ReportItem