const citationReference = {
    "7-38-001": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483571#JD_7-38-001",
    "7-38-005": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483574#JD_7-38-005",
    "7-38-010": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483577#JD_7-38-010",
    "7-38-011": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483580#JD_7-38-011",
    "7-38-012": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483583#JD_7-38-012",
    "7-38-015": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483586#JD_7-38-015",
    "7-38-020": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483589#JD_7-38-020",
    "7-38-025": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483594#JD_7-38-025",
    "7-38-030": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483597#JD_7-38-030",
    "7-38-035": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483603#JD_7-38-035",
    "7-38-040": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483606#JD_7-38-040",
    "7-38-060": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483609#JD_7-38-060",
    "7-38-065": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483611#JD_7-38-065",
    "7-38-070": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483614#JD_7-38-070",
  
    "7-38-130": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483743#JD_7-38-130",
    "7-38-132": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483752#JD_7-38-132",
    "7-38-134": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483757#JD_7-38-134",
    "7-38-136": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483770#JD_7-38-136",
    "7-38-138": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483774#JD_7-38-138",
    "7-38-140": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483785#JD_7-38-140",
    "7-38-142": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483794#JD_7-38-142",
    "7-38-144": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483814#JD_7-38-144",
    "7-38-146": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483817#JD_7-38-146",
    "7-38-148": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483821#JD_7-38-148",
    "7-38-150": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483830#JD_7-38-150",
  
    "7-38-190": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483845#JD_7-38-190",
    "7-38-212": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483856#JD_7-38-212",
    "7-38-214": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483859#JD_7-38-214",
    "7-38-220": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483868#JD_7-38-220",
    "7-38-495": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483893#JD_7-38-495",
    "7-38-500": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483896#JD_7-38-500",
    "7-38-510": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483899#JD_7-38-510",
    "7-38-515": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483902#JD_7-38-515",
    "7-38-520": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483905#JD_7-38-520",
    "7-38-525": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483908#JD_7-38-525",
    "7-38-530": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483912#JD_7-38-530",
    "7-38-535": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483919#JD_7-38-535",
    "7-38-545": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483926#JD_7-38-545",
    "7-38-550": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483929#JD_7-38-550",
    "7-38-555": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483933#JD_7-38-555",
    "7-38-560": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483938#JD_7-38-560",
    "7-38-570": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483941#JD_7-38-570",
    "7-38-575": "https://codelibrary.amlegal.com/codes/chicago/latest/chicago_il/0-0-0-2483946#JD_7-38-575",
}

export {citationReference as default};