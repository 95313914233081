// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import React from "react";
import  {  jsx, css  }  from  '@emotion/core'

export default function GoogleMap(props) {

    const width = (window.innerWidth) - 50

    const mapStyle = css`
        border: 0;
    `

    return (
        <React.Fragment>
            <iframe
                width = {width}
                height = {width}
                frameBorder = "0"
                css = {mapStyle}
                allowFullScreen
                src = {`https://www.google.com/maps/embed/v1/place?key=AIzaSyCCq28eDb4J9KFmv9-_otOIwEoBYsGi6-M&q=place_id:${props.placeID}`}
            >
            </iframe>
        </React.Fragment>
    )
}