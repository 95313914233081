// Style variables

    // Colors
    export const colors = {
        lightGray: "#f8f9fa",
        medGray: "#e8eaed",
        hoverGray: "#FAFAFA",
        hoverBlue: "#e8f0fe",
        blue: "#2C73E9",
        white: "#ffffff",
        blackFont: "#212121",
        darkFont: "#1A1F36",
        grayFont: "#3C4257",
        darkGrayFont: "#666",
        blueFont: "#1967d2",
        darkBlueFont: "#003ea0",
        borderLtGray: "#e6e6e6",
        borderGray: "#dadce0",
        borderBlue: "#4285f4",
        cardWhite: "#fefffe",
        cardBody: "#f7fafc",
        cardBorder: "#e3e8ee",
        switchActive: "#5469D4",
        fadedGray: "#aab7c4",
        // chipRed: "#febbbb",
        chipRed: "#FED7D7",
        chipGreen: "#C6F6D5",
        chipPurple: "#E9D8FD",
        chipOrange: "#FEEBC8",
        chipGray: "#2D3748",
        menuGray: "#747574",
        menuCardBackground: "#2C3E50"
    }