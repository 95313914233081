// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */

import React from 'react'
import  {  jsx  }  from  '@emotion/core'

import ReportItem from "./report_item";

import parseReport from "../../utility_functions/inspection_report_processing/parse_report"

function ReportItemContainer(props) {
    const { violations } = props

    const violationsArr = parseReport(violations)
    console.log('violationsArr:', violationsArr)

    const allItems = violationsArr.map((el, i) => {
        return <ReportItem data={el} key={i} />
    })

    return (
        <React.Fragment>
            {allItems}
        </React.Fragment>
    )
}

export default ReportItemContainer