// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */

import React, { useState, useEffect, useRef } from "react";
import  {  jsx, css, keyframes  }  from  '@emotion/core'
import { useParams, withRouter, Link } from "react-router-dom";
import { IconContext } from "react-icons";
import { MdBookmarkBorder } from "react-icons/md";
// my css
import { colors } from "../style/variables";
import { fonts, card, page, chiButton } from "../style/global";
// misc functions
import postData from "../utility_functions/post_data";
import getInspections from "../utility_functions/cdp_data"
import fuzzyFilterInspections from "../utility_functions/fuzzy_search_inspections"
import createInspectionsList from "../utility_functions/create_inspections_list"
import { sortChronological, sortResult, sortViolations } from "../utility_functions/sort_inspections_list"
// my components
import GooglePlaceView from "../components/google_place_view";
import ChiSelect from "../components/chi_select";
import OrderButton from "../components/order_button";
import FilterSwitch from "../components/filter_switch";
import InspectionContainer from "../components/inspection_components/location_inspection_container";


const chiURL = process.env.REACT_APP_URL;

function LocationPage(props) {
    // Functions that manage state \/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/

    const [st_locationData, setLocationData] = useState(null)
    // const [st_inspectionData, setInspectionData] = useState(null)
    // const [st_filteredInspections, setFilteredInspections] = useState(null)
    const [st_sortedInspections, setSortedInspections] = useState(null)
    const [st_sortOption, handleSortOption] = useState("date")
    const [st_sortOrder, setSortOrder] = useState(true)
    const [st_listOrder, setListOrder] = useState(true)

    const ref_list = useRef()
    const ref_firstClick = useRef(false)
    const ref_sortOption = useRef(-1)
    ref_sortOption.current += 1
    console.log("render", ref_sortOption.current)

    // /\/\/\/\/\/\/\/\//\/\/\/\//\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

    // Functions that manage sorting \/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/

    function sortListByMethod(list, method, delay) {
        if (delay) {
            switch (method) {
                case "date":
                    return setTimeout(() => {
                        setSortedInspections(sortChronological(list))
                        handleSortOption(method)
                    }, 300);
                case "inspection_result":
                    return setTimeout(() => {
                        setSortedInspections(sortResult(list))
                        handleSortOption(method)
                    }, 300); 
                case "number_of_violations":
                    return setTimeout(() => {
                        setSortedInspections(sortViolations(list))
                        handleSortOption(method)
                    }, 300);      
                default:
                    return setTimeout(() => {
                        setSortedInspections(sortChronological(list))
                        handleSortOption(method)
                    }, 300);
            }
        } else {
            switch (method) {
                case "date":
                    return sortChronological(list);
                case "inspection_result":
                    return sortResult(list);    
                case "number_of_violations":
                    return sortViolations(list);    
                default:
                    return sortChronological(list);
            }
        }
    }

    // Functions to fetch results from Google \/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/

    async function fetchPlaceDetails(val) {
        const fnURL = `${chiURL}/.netlify/functions/g_place_details`
        const data = {place_ID: val}
        const resp = await postData(fnURL, data)
        // console.log("here is the place data: ", resp)
        return new Promise(resolve => resolve(resp.msg));
    }

    useEffect(() => {
        // This conditional statement below checks to see if the location_inspections_list data is already cached in localstorage and
        // saves it to state so the inspections list will be rendered from this stored data
        if (localStorage.getItem('location_data') && JSON.parse(localStorage.getItem('location_data')).placeID === props.match.params.placeID) {
            const locationData = JSON.parse(localStorage.getItem('location_data'))
            setLocationData(locationData.placeData)
            const inspectionsList = createInspectionsList({inspections: locationData.filteredInspections, placeName: locationData.placeData.name})
            const sortedList = sortListByMethod(inspectionsList, st_sortOption, false)
            setSortedInspections(sortedList)
        } else {
        // If the location_inspections_list key isn't in local storage, OR if the location placeID on the inspections list object is different than
        // the current placeID passed from props, fetch the inspections data from Socrata.
            (async function() {
                const initState = await fetchPlaceDetails(props.match.params.placeID)
                setLocationData(initState.result)

                const inspections = await getInspections(initState.result.address_components)
                console.log('inspections:', inspections)
                // setInspectionData(inspections)
                
                const placeName = initState.result.name
                const filteredInspections = fuzzyFilterInspections({inspections: inspections, placeName: placeName})
                console.log('filteredInspections:', filteredInspections)

                const locationDataCache = {
                    placeID: props.match.params.placeID,
                    placeData: initState.result,
                    filteredInspections: filteredInspections
                }
                localStorage.setItem('location_data', JSON.stringify(locationDataCache))

                const inspectionsList = createInspectionsList({inspections: filteredInspections, placeName: placeName})
                console.log('inspectionsList:', inspectionsList)
                const sortedList = sortListByMethod(inspectionsList, st_sortOption, false)
                setSortedInspections(sortedList)
                // setFilteredInspections(filteredInspections)
            })()
        }
    }, [props.match.params.placeID])

    // /\/\/\/\/\/\/\/\//\/\/\/\//\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\


    // Style expressions \/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/

        // Style for Card

        const { cardContainer, cardHead, cardHeadSm, cardTitle, cardBody, verticalSpacer } = card

        // Style for Card End

        // Style for Card Buttons

        const cardBtn = css`
            line-height: 3.8rem;
            display: inline-block;
            vertical-align: middle;
        `

        // Style for Card Buttons End

        // Style for inspections list
        const fadeList = keyframes`
            0%, 100% {
                opacity: 1;
            }
            45%, 65% {
                opacity: 0;
            }
        `

        const inspectionListContainer = css`
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: -1.5rem;

            &.faded {
                animation: ${fadeList} 0.6s ease-out;
            }
        `

        // Style for inspections list end

        // List fade effect function
        function fn_FadeList() {
            ref_list.current.classList.toggle("faded")
            setTimeout(() => {
                ref_list.current.classList.toggle("faded")
            }, 650);
        }
        // List fade effect function end
        
        // sortSelect functions
        function handleSortChange(option) {
            fn_FadeList()
            sortListByMethod(st_sortedInspections, option.value, true)
        }

        // sortOrder functions
        function handleSortOrder() {
            fn_FadeList()
            setSortOrder(!st_sortOrder)
            setTimeout(setListOrder.bind(null, !st_listOrder), 300)
        }
        // sortOrder functions End

        

    // /\/\/\/\/\/\/\/\//\/\/\/\//\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\

    // console.log(props)
    // console.log("st_locationData ", st_locationData)
    const { history } = props
    const { placeID } = useParams();
    const placeName = st_locationData?.name
    const placeAddress = st_locationData?.formatted_address
    const placeGoogleSite = st_locationData?.url
    // console.log('placeName:', placeName)
    return (
        <React.Fragment>
            {/* <div css={cs_grade}>Grade:
                <span css={cs_gradeResult}>A+</span>
            </div> */}
            {/* <div onClick={history.goBack}>Go Back</div>
            <div onClick={history.goForward}>Go Forward</div> */}
            {/* <Link to={location => {history.goBack()}}>Go Back</Link> */}
            {/* <button onClick={() => updateState(!stateUpdated)}>Update state</button> */}
            {/* Below is the code for fetching images */}
            {/* { st_locationData ? <StreetViewImage name={st_locationData.name} address={st_locationData.formatted_address} /> : null } */}
            <div css={page.container}>
                {/* First Card */}
                <div css={cardContainer}>
                    <div css={cardHead}>
                        <div css={verticalSpacer}>
                            <h1 css={fonts.cardTitle}>{placeName}</h1>
                            <h3 css={fonts.cardSubTitle}>{placeAddress}</h3>
                        </div>
                    </div>
                    <div css={cardBody}>
                        <div css={verticalSpacer}>
                            {placeGoogleSite ? 
                                <a css={[chiButton, fonts.button]} href={placeGoogleSite} target="_blank">
                                    <span>View On Google</span>
                                </a>
                                :
                                null
                            }
                            <div css={[chiButton, fonts.button]}>
                                <span>Save</span>
                                <IconContext.Provider value={{color: `${colors.grayFont}`, size: "1.8rem"}}>
                                    <MdBookmarkBorder css={{marginLeft: "0.2rem"}} />
                                </IconContext.Provider>
                            </div>
                        </div>

                        {st_locationData ? <GooglePlaceView placeID={placeID} placeAddress={placeAddress} placeName={placeName}  /> : null}
                    
                    </div>

                </div>
                {/* First Card End */}
                {/* Second Card */}
                <div css={cardContainer}>
                    <div css={cardHeadSm}>
                        <div css={verticalSpacer}>
                            <h2 css={fonts.cardTitle}>Health Inspections</h2>
                        </div>
                    </div>
                    <div css={cardBody}>
                        <div css={verticalSpacer}>
                            <div css={[{width: "40%", marginRight: "1.5rem"}, cardBtn]}>
                                <ChiSelect 
                                handleChange={handleSortChange} 
                                options={
                                    [
                                        { value: 'date', label: 'Date' },
                                        { value: 'inspection_result', label: 'Inspection Result' },
                                        { value: 'number_of_violations', label: 'Number of Violations' }
                                    ]
                                } />
                            </div>
                            <div css={[{marginRight: "1.5rem"}, cardBtn]}>
                                <OrderButton handleChange={handleSortOrder} order={st_sortOrder} firstClick={ref_firstClick} />
                            </div>
                            <div css={cardBtn}>
                                <FilterSwitch />
                            </div>
                            {/* <div css={{width: "20%", display: "inline-block"}}>
                                <div>
                                    <FilterSwitch />
                                </div>
                            </div> */}
                        </div>
                        <div>
                            <div ref={ref_list} css={inspectionListContainer}>
                                {st_sortedInspections ? 
                                    st_listOrder ? st_sortedInspections : st_sortedInspections.slice().reverse()
                                :
                                    null
                                }
                            </div>
                        {/* {st_sortedInspections ? 
                            <div ref={ref_list} css={inspectionListContainer}>
                                {st_listOrder ? st_sortedInspections : st_sortedInspections.slice().reverse()}
                            </div>
                            :
                            null
                        } */}
                        </div>
                        {/* <div>
                        {st_filteredInspections ? 
                            <InspectionContainer inspections={st_filteredInspections} placeName={placeName} />
                            :
                            null
                        }
                        </div> */}
                    </div>
                </div>
                {/* Second Card End */}
            </div>

        </React.Fragment>
    )
}

export default withRouter(LocationPage)