// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import  { css }  from  '@emotion/core';
import { colors } from './variables';


// Style variables

    // styles
    export const fonts = {
        bold: css`font-weight: bold;`,
        header: css`
                font-family:'Roboto',arial,sans-serif;
                font-size: 2.5rem;
                font-weight: 500;
                padding: 0.6rem 0;
                margin: 0;
            `,
        cardTitle: css`
                font-family:'Roboto',arial,sans-serif;
                font-size: 2rem;
                line-height: 2.8rem;
                font-weight: 400;
                color: #1A1F36;
                margin: 0;
            `,
        cardSubTitle: css`
                font-family:'Roboto',arial,sans-serif;
                font-size: 1.4rem;
                line-height: 2.2rem;
                font-weight: 400;
                color: #3C4257;
                display: inline-block;
                margin: 0;
            `,
        regular: css`
                font-family: 'Roboto',arial,sans-serif;
                font-size: 1.4rem;
                color: #3C4257;
            `,
        button: css`
            color: ${colors.grayFont};
            text-align: center;
            font-weight: 500;
            font-size: 1.4rem;
            font-family: 'Roboto',arial,sans-serif;
        `
    }

    export const page = {
        container: css`
            width: 100vw;
            position: absolute;
        `
    }

    export const card = {
        cardContainer: css`
            margin: 3rem auto 0;
            width: calc(100vw - 2rem);
            box-shadow: 0 0.4rem 0.4rem rgba(0,0,0,0.25), 0 0.3rem 0.6rem rgba(0,0,0,0.12);
            border-radius: 0.4rem;
            background: ${colors.cardBody};

            &:last-of-type {
                margin-bottom: 10rem;
            }
        `,
        cardHead: css`
            height: 8rem;
            border-radius: 0.4rem 0.4rem 0 0;
            padding: 0 1.5rem;
            background: ${colors.cardWhite};
            box-shadow: inset 0px -1px 0px ${colors.cardBorder};
        `,
        cardHeadSm: css`
            height: 6rem;
            border-radius: 0.4rem 0.4rem 0 0;
            padding: 0 1.5rem;
            background: ${colors.cardWhite};
            box-shadow: inset 0px -1px 0px ${colors.cardBorder};
        `,
        cardTitle: css`
            display: inline-block;
        `,
        cardBody: css`
            padding: 0 1.5rem 1.5rem;
        `,
        verticalSpacer: css`
            padding: 1.5rem 0;
        `,
        cardSubContainer: css`
            background: ${colors.cardWhite};
            width: 100%;
            margin: 0 auto;
            border-radius: 0.4rem;
            position: relative;
            z-index: 2;
            overflow: hidden;
            box-shadow: 0 0 0 0.1rem #E3E8EE;
        `,
    }

    const arrow = `
        content:'';
        position: absolute;
        bottom: -3.5rem;
        transform: rotate(45deg);
        width: 7rem;
        height: 7rem;
        border-radius: 0 0 0.4rem 0;
        background: ${colors.menuCardBackground};
        box-shadow: 0.4rem 0.4rem 0.4rem 0rem rgba(0,0,0,0.25), 0.3rem 0.3rem 0.6rem -0.3rem rgba(0,0,0,0.12);
    `
    const test = `
        right: 40px;
        content:'';
        position: absolute;
        bottom: -6rem;
        width: 0.5rem;
        height: 7.3rem;
        transform: rotate(-45deg);
        border-radius: 0 0 0.1rem 0.3rem;
        background: ${colors.menuCardBackground};
        box-shadow: 0.4rem 0.4rem 0.4rem 0rem rgba(0,0,0,0.25), 0.3rem 0.3rem 0.6rem -0.3rem rgba(0,0,0,0.12);
    `
    
    export const menuCard = {
        cardContainer: css`
            position: fixed;
            left: 1rem;
            bottom: 11.1rem;
            margin: 0 auto;
            width: calc(100vw - 2rem);
            height: 66vh;
            box-shadow: 0 0.4rem 0.4rem rgba(0,0,0,0.25), 0 0.3rem 0.6rem rgba(0,0,0,0.12);
            border-radius: 0.4rem;
            background: ${colors.menuCardBackground};
            z-index: 5;
        `,
        historyArrow: css`
            &:before {
                left: calc((((100vw / 2) - 3.6rem) * 0.75) - 4.475rem);
                ${arrow}
            }
        `,
        bookMarkArrow: css`
            &:before {
                right: calc((((100vw / 2) - 3.6rem) * 0.75) - 4.475rem);
                ${arrow}
            }
        `,
        profileArrow: css`
            &:before {
                right: calc((((100vw / 2) - 3.6rem) * 0.25) - 1.25rem);
                content:'';
                z-index: 1;
                position: absolute;
                bottom: -4.95rem;
                width: 0.4rem;
                height: 4.95rem;
                border-radius: 0 0 0.2rem 0.2rem;
                background: ${colors.menuCardBackground};
                box-shadow: 0 -1px 0px ${colors.menuCardBackground}, 0.4rem 0.4rem 0.4rem rgba(0,0,0,0.25), 0.3rem 0.3rem 0.6rem rgba(0,0,0,0.12);
            }

            &:after {
                right: calc((((100vw / 2) - 3.6rem) * 0.25) + 1.55rem);
                content:'';
                position: absolute;
                bottom: -6rem;
                width: 0.1rem;
                height: 7.3rem;
                transform: rotate(-45deg);
                background: ${colors.menuCardBackground};
                box-shadow: -0.1rem 0.8rem 0.4rem 0.33rem rgba(0,0,0,0.25);
            }
        `,
        profileTriangle: css`
            position: absolute;
            width: 0;
            height: 0;
            border-top: 4.9rem solid ${colors.menuCardBackground};
            border-left: 4.9rem solid transparent;

            bottom: -4.9rem;
            right: calc((((100vw / 2) - 3.6rem) * 0.25) - 0.95rem);
            box-shadow: 1px -1px 0px ${colors.menuCardBackground};
            z-index: 1;
        `,
        cardHead: css`
            height: 8rem;
            border-radius: 0.4rem 0.4rem 0 0;
            padding: 0 1.5rem;
            background: ${colors.cardWhite};
            box-shadow: inset 0px -1px 0px ${colors.cardBorder};
        `,
        cardHeadSm: css`
            height: 6rem;
            border-radius: 0.4rem 0.4rem 0 0;
            padding: 0 1.5rem;
            background: ${colors.cardWhite};
            box-shadow: inset 0px -1px 0px ${colors.cardBorder};
        `,
        cardTitle: css`
            display: inline-block;
        `,
        cardBody: css`
            padding: 0 1.5rem 1.5rem;
        `,
        verticalSpacer: css`
            padding: 1.5rem 0;
        `,
        cardSubContainer: css`
            background: ${colors.cardWhite};
            width: 100%;
            margin: 0 auto;
            border-radius: 0.4rem;
            position: relative;
            z-index: 2;
            overflow: hidden;
            box-shadow: 0 0 0 0.1rem #E3E8EE;
        `,
    }

    export const chiButton = css`
        display: inline-block;
        background: ${colors.white};
        height: 2.8rem;
        min-width: 10rem;
        padding: 0 1.5rem;
        margin-right: 1.5rem;
        line-height: 2.8rem;
        text-align: center;               
        color: ${colors.grayFont};
        font-weight: 600;
        font-size: 1.4rem;
        border-radius: 0.4rem;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(60, 66, 87, 0.23) 0px 2px 5px 0px;
        transition: box-shadow 0.33s ease; 

        &:hover {
            box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(60, 66, 87, 0.23) 0px 2px 5px 0px, rgba(60, 66, 87, 0.23) 0px 3px 9px 0px;
            cursor: pointer;
            transform: scale(1.02) 
        }

        & span, svg {
            display: inline-block;
            vertical-align: middle;
        }
    `
    export const resultBadge = css`
        padding: 0.2rem 1rem;
        border-radius: 1rem;
        font-size: 1.4rem;
        font-weight: 500;
        display: inline-block;
    `
    export const resultColor = {
        "Pass": colors.chipGreen,
        "Pass w/ Conditions": colors.chipOrange,
        "Fail": colors.chipRed,
        "Out of Business": colors.chipGray,
        "Not Ready": colors.chipPurple,
        "No Entry": colors.chipPurple
    }