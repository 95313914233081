// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import SearchResult from "./search_results";

import React, { useState, useCallback, useRef, useEffect, useLayoutEffect } from "react";
import  {  jsx, css, keyframes  }  from  '@emotion/core';
import { IconContext } from "react-icons";
import { MdSearch } from 'react-icons/md';
import { debounce } from "lodash";
import { v4 as uuidv4 } from 'uuid';

import googleLogo from '../misc/images/powered_by_google_on_white.png';

const chiURL = process.env.REACT_APP_URL;

export default function SearchBar(props) {
    const { logoRef } = props

    const searchInput = useRef()

    // State and hook expressions
    const [st_input, handleInput] = useState('')
    const [st_queryCount, handleQueryCount] = useState(0)
    const [st_resultBoxState, handleResultBox] = useState('closed')
    const handleDebounce = useCallback(debounce((query, count) => {console.log("my query count is", count); debounceFetch(query, count)}, 1000), [])
    const [st_firstFocus, setFirstFocus] = useState(false)
    const [st_focus, handleFocus] = useState(false)
    const [st_autoComplete, handleAutoComplete] = useState(null)
    const [st_sessionToken, setSessionToken] = useState(null)

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevCount = usePrevious(st_queryCount)

    // Function below used to determine if results box should expand or retract
    useEffect(() => {
        if (prevCount < 3 && st_queryCount > 2) {
            handleResultBox('expand')
        } else if (prevCount > 2 && st_queryCount < 3) {
            handleResultBox('retract')
        } else if (st_queryCount < 2) {
            handleResultBox('closed')
        }
    }, [prevCount, st_queryCount])

    useEffect(() => {
        if (st_firstFocus) {
            logoRef.current.classList.add("faded")
        }
    }, [st_firstFocus])

    useEffect(() => {
        const sessionToken = uuidv4()
        setSessionToken(sessionToken)
    }, [])

    // Style variables
        // Colors 
        const lightGray = "#dfe1e5"
        const medGray = "#e8eaed"
        const hoverGray = "#f4f4f4"
        const blue = "#2C73E9"
        const white = "#ffffff"
        const blackFont = "#212121"
        let backColor = white
        let fontColor = blackFont
    
    // Style expressions
    const searchContainer = css`
        width: 100%;
        margin: 2.5rem auto 0;
    `
    // const searchContainer = css`
    //     position: absolute;
    //     width: 100%;
    //     margin: 0 auto;
    // `

    const inputContainer = css`
        position: relative;
        z-index: 3;
        display: flex;
        border: .1rem solid ${lightGray};
        border-radius: 2.4rem;
        height: 4.4rem;
        margin: 0 auto;
        width: calc(100vw - 5rem);
        align-items: center;
        padding: 0 1.5rem;
        background-color: ${backColor};

        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);

        &:hover {
            width: calc(100vw - 4.8rem);
            height: 4.5rem;
            box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);

            & .svg {
                height: 2.04rem !important;
                width:  2.04rem !important;
            }
        }

        @media (min-width: 768px) {
            width: 50%;

            &:hover {
                width: 52%;
                height: 4.5rem;
            }
        }
    `

    const css_googleLogo = css`
        width: auto;
        height: 1rem;
        position: absolute;
        bottom: 0.1rem;
        right: 1.5rem;
    `

    // const searchContainer = css`
    //     position: relative;
    //     width: 100%;
    //     margin: 0 auto;
    // `

    // const inputContainer = css`
    //     position: relative;
    //     z-index: 3;
    //     display: flex;
    //     border: .1rem solid ${lightGray};
    //     border-radius: 2.4rem;
    //     height: 4.4rem;
    //     margin: 0 auto;
    //     width: 50%;
    //     align-items: center;
    //     padding: 0 1.5rem;
    //     background-color: ${backColor};

    //     box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    //     transition: all 0.3s cubic-bezier(.25,.8,.25,1);

    //     &:hover {
    //         width: 52%;
    //         height: 4.5rem;
    //         box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);

    //         & .svg {
    //             height: 2.04rem !important;
    //             width:  2.04rem !important;
    //         }
    //     }
    // `

    const animateUp = keyframes`
        from {top: 31rem;}
        to {top: 20rem;}
    `
    const animateUpDesktop = keyframes`
        from {top: 31rem;}
        to {top: 5rem;}
    `

    let inputAnimate = css`
        animation: ${animateUpDesktop} 1s ease-in-out;
        top: 5rem;

        @media (min-width: 768px) {
            animation: ${animateUpDesktop} 1s ease-in-out;
            top: 5rem;
        }
    `
    // let inputAnimate = css`
    //     animation: ${animateUp} 1s ease-in-out;
    //     top: 20rem;

    //     @media (min-width: 768px) {
    //         animation: ${animateUpDesktop} 1s ease-in-out;
    //         top: 5rem;
    //     }
    // `

    // let inputAnimate = css`
    //     animation: ${animateUp} 1s ease-in-out;
    //     top: 5rem;
    //     box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    // `
    
    let searchBarUP = css`
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    `

    const inputStyle = css`
        border: 0px;
        height: 3.5rem;
        width: 90%;
        font-size: 1.75rem;
        margin: 0 auto;

        &:focus {outline: 0px;}

        @media (min-width: 768px) {
            height: 3.8rem;
        }
    `

    // Styles and animations for search icon
    const iconStyle = css`
        fill: #9AA0A6;
        height: 2rem;
        width: 2rem;
    `
    const animateActive = keyframes`
        from {fill: #9AA0A6;}
        to {fill: ${blue};}
    `
    const iconActive = css`
        animation: ${animateActive} 0.3s cubic-bezier(.25,.8,.25,1);
        fill: ${blue};
    `
    const animateInactive = keyframes`
        from {fill: ${blue};}
        to {fill: #9AA0A6;}
    `
    const iconInactive = css`
        animation: ${animateInactive} 0.3s cubic-bezier(.25,.8,.25,1);
        fill: #9AA0A6;
    `

    // Animations for search border
    const borderAnimActive = keyframes`
        from {border: .1rem solid ${lightGray};}
        to {border: .1rem solid ${blue};}
    `
    const borderActive = css`
        animation: ${borderAnimActive} 0.3s cubic-bezier(.25,.8,.25,1);
        border: .1rem solid ${blue};;
    `
    const borderAnimInactive = keyframes`
        from {border: .1rem solid ${blue};}
        to {border: .1rem solid ${lightGray};}
    `
    const borderInactive = css`
        animation: ${borderAnimInactive} 0.3s cubic-bezier(.25,.8,.25,1);
        border: .1rem solid ${lightGray};;
    `

    const iconAnimate = st_focus ? iconActive : iconInactive;
    const borderAnimate = st_focus ? borderActive : borderInactive;

    // Event functions

        // Functions to fetch results from Google

        function onChange(e) {
            const val = e.target.value

            handleInput(val)
            handleQueryCount(val.length)
            handleDebounce(val, val.length)
        }

        async function postData(url = '', data = {}) {
            // Default options are marked with *
            const response = await fetch(url, {
              method: 'POST', // *GET, POST, PUT, DELETE, etc.
              headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: JSON.stringify(data) // body data type must match "Content-Type" header
            });
            return await response.json(); // parses JSON response into native JavaScript objects
        }

        async function debounceFetch(val, queryCount) {
            console.log("current query zcount: ", queryCount)
            console.log("the val is ", val)
            if (queryCount < 3) {return null;}
            const fnURL = `${chiURL}/.netlify/functions/g_auto_complete`
            const data = {
                search_term: val,
                session_token: st_sessionToken
            }

            const resp = await postData(fnURL, data)
            console.log(resp)
            handleAutoComplete(resp.msg.predictions)
        }

        // function handleFirstFocus(e) {

        function handleScrollFix(e) {
            // create invisible dummy input to receive the focus first
            const fakeInput = document.createElement('input')
            fakeInput.setAttribute('type', 'text')
            fakeInput.style.position = 'absolute'
            fakeInput.style.opacity = 0
            fakeInput.style.height = 0
            fakeInput.style.fontSize = '16px' // disable auto zoom

            // you may need to append to another element depending on the browser's auto 
            // zoom/scroll behavior
            document.body.prepend(fakeInput)

            // focus so that subsequent async focus will work
            fakeInput.focus()

            setTimeout(() => {

                // now we can focus on the target input
                searchInput.current.focus()

                // cleanup
                fakeInput.remove()

            }, 1000)
        }

    return (
        <React.Fragment>

            <div className="searchContainer" css={searchContainer}>
            {/* <div className="searchContainer" css={[searchContainer, position, (st_firstFocus ? inputAnimate : null)]}> */}
                <div css={[inputContainer, (st_firstFocus ? borderAnimate : null), (st_firstFocus ? searchBarUP : null)]}>
                {/* <div css={[inputContainer, position, (st_firstFocus ? borderAnimate : null), (st_firstFocus ? inputAnimate : null)]}> */}

                    <IconContext.Provider value={{color: "#9AA0A6", size: "2.1rem"}}>    
                    {/* <IconContext.Provider value={{color: "#9AA0A6", style: {height: "2.1rem", width: "2.1rem"}, attr: {height: "unset", width: "unset"}}}>     */}
                        <MdSearch css={iconStyle, (st_firstFocus ? iconAnimate: null)} />
                    </IconContext.Provider>
        
                    <input type="text" value={st_input} css={inputStyle}
                        onChange={onChange}
                        onFocus={() => {setFirstFocus(true); handleFocus(true)}}
                        onBlur={e => handleFocus(false)}
                        onClick={handleScrollFix}
                        // onTouchEnd={e => handleScrollFix(e)}
                        placeHolder="Search Chicago health inspections"
                        ref={searchInput}
                    />

                    <img css={css_googleLogo} src={googleLogo} alt="powered by Google logo"/>
                </div>

                {st_autoComplete ? <SearchResult resultBoxState={st_resultBoxState} autoComplete={st_autoComplete}/> : null }

            </div>
        </React.Fragment>
    )
}