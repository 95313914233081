export default async function getInspections(addressArr) {
    const url = "https://data.cityofchicago.org/resource/4ijn-s7e5.json?"
    const { REACT_APP_CDP_TOKEN } = process.env

    const addressNumber = addressArr[0].short_name
    const addressStreet = addressArr[1].short_name.toUpperCase()
    const address = `${addressNumber} ${addressStreet}`

    const response = await fetch(
        `${url}$$app_token=${REACT_APP_CDP_TOKEN}&$where=upper(address)='${address} '`
    )
  
    const results = await response.json()
  
    if (results.length === 0) {
      console.log("fetching second results")
    //   const addressNumber = capitalAddress.match(/^\d+/)[0]
    //   const addressStreet = capitalAddress.match(/\s.*/)[0].trim()
  
      const secondResponse = await fetch(
          `${url}$$app_token=${REACT_APP_CDP_TOKEN}&$where=starts_with(address, '${addressNumber}') AND upper(address) like '%25${addressStreet}%25'`
      )
  
      const secondResults = await secondResponse.json()
      return new Promise(resolve => resolve(secondResults));
    }
  
    return new Promise(resolve => resolve(results));
  }