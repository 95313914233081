// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */

import React, { useState, useEffect } from "react";
import  {  jsx, css, keyframes  }  from  '@emotion/core'
import { colors } from "../style/variables";
import { fonts, card } from "../style/global";
import StreetViewImage from "../components/street_view_image"
import GoogleMap from "../components/google_map"

export default function GooglePlaceView(props) {

    const [moveMapBox, handleMapBox] = useState(false)

   
// Style for mapViewBox
    const mapViewBox = css`
        background: ${colors.cardWhite};
        width: 100%;
        margin: 0 auto;
        border-radius: 0.4rem;
        position: relative;
        z-index: 2;
        overflow: hidden;
        box-shadow: 0 0 0 0.1rem #E3E8EE;
    `
    const mapContainer = css`
        width: 200%;
        position: relative;
        z-index: 1;
        box-shadow: inset 0 -0.2rem 0 #E3E8EE;
        transition: transform 0.33s ease-in-out;

        & .mapBox {
            display: inline-block;
            width: 50%;
            height: 100%;

            img {
                width: 100%;
            }
        }
    `
    const moveContainer = css`
        transform: translate3d(-50%, 0, 0);
    `

    const mapButtonContainer = css`
        height: 4rem;

        & .active {
            color: ${colors.blue};
            box-shadow: inset 0 -0.2rem 0 ${colors.blue}, inset 0 0 0.5rem rgba(0, 0, 0, 0.5) !important;
            background: ${colors.cardBorder};
        }
    `

    const mapButton = css`
        display: inline-block;
        height: 4rem;
        line-height: 4rem;
        width: 50%;
        transition: color 0.33s cubic-bezier(.25,.8,.25,1), box-shadow 0.33s cubic-bezier(.25,.8,.25,1), background 0.33s cubic-bezier(.25,.8,.25,1);

        &:hover {
            cursor: pointer;
        }

        &:first-of-type {
            border-radius: 0 0 0 0.4rem;
            box-shadow: 0.1rem 0 0 #E3E8EE;
        }
        
        &:last-of-type {
            border-radius: 0 0 0.4rem 0;
            box-shadow: -0.1rem 0 0 #E3E8EE;
        }


        & span {
            display: inline-block;
            vertical-align: middle;
        }
    `

// Style for mapViewBox End

// mapBox functions
    function handleMapBtnClick(state) {
        if (state === moveMapBox) {
            return
        } else {
            handleMapBox(state)
        }
    }
// mapBox functions End


    const { placeID, placeAddress, placeName } = props;

    return (
        <div css={mapViewBox}>
            <div css={[mapContainer, (moveMapBox ? moveContainer : null)]}>
                <div className="mapBox">
                    <GoogleMap placeID={placeID} />
                </div>
                <div className="mapBox">
                    <StreetViewImage address={placeAddress} name={placeName} />
                </div>
            </div>
            <div css={mapButtonContainer}>
                <div css={[mapButton, fonts.button]} className={moveMapBox ? null : "active"} onClick={handleMapBtnClick.bind(null, false)}>
                    <span>Map</span>
                </div>
                <div css={[mapButton, fonts.button]} className={moveMapBox ? " active" : null } onClick={handleMapBtnClick.bind(null, true)}>
                    <span>Street View</span>
                </div>
            </div>
        </div>
    )
}