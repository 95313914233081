export default async function getReport(id) {
  const url = "https://data.cityofchicago.org/resource/4ijn-s7e5.json?"
  const { REACT_APP_CDP_TOKEN } = process.env

  const response = await fetch(
      `${url}$$app_token=${REACT_APP_CDP_TOKEN}&inspection_id=${id}`
  )

  const results = await response.json()

  return new Promise(resolve => resolve(results));
}