// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */

import React, { useState } from "react";
import Switch from "react-switch";
import  {  jsx, css  }  from  '@emotion/core';
import { fonts } from "../style/global";
import { colors } from "../style/variables";
import { IconContext } from "react-icons";
import { MdBookmarkBorder } from "react-icons/md";
import { FcCancel } from "react-icons/fc";


export default function FilterSwitch() {

    const [checked, handleCheck] = useState(false)

   
// Style for Switch
    const shadowInactive = "rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(60, 66, 87, 0.23) 0px 2px 5px 0px"
    const shadowActive = "rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(60, 66, 87, 0.23) 0px 2px 5px 0px, rgba(60, 66, 87, 0.23) 0px 3px 9px 0px"

    const switchContainer = css`
        & .react-switch {
            border-radius: 1.4rem !important;
            vertical-align: middle;
        }
        
        & .react-switch-handle {
            &:hover {
                box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(60, 66, 87, 0.23) 0px 2px 5px 0px, rgba(60, 66, 87, 0.23) 0px 3px 9px 0px !important;
            }
        }

        & .react-switch-bg {
            box-shadow: rgba(60, 66, 87, 0.16) 0px 0px 0px 1px;
        }
    `

    const iconContainer = css`
        height: 100%;
        line-height: 2.8rem;
        text-align: center; 

        & svg {
            display: block;
            margin: auto;
            height: 100%;
        }
    `

    const cancel = css`
        & path {
            fill: white;
        }
    `
    const label = css`
        vertical-align: middle;
        padding-left: 0.5rem;
    `

// Style for Switch End

    return (
        <div css={switchContainer}>
            <Switch 
                checked={checked}
                onChange={handleCheck}
                className="react-switch"
                offColor={colors.cardBorder}
                onColor={colors.switchActive}
                boxShadow={shadowInactive}
                activeBoxShadow={shadowActive}
                height={21}
                width={36}
                uncheckedIcon={
                    <div css={iconContainer}>
                        <IconContext.Provider value={{color: `${colors.darkGrayFont}`, size: "1.5rem"}}>
                            <MdBookmarkBorder />
                        </IconContext.Provider>
                    </div>
                }
                checkedIcon={
                    <div css={[iconContainer, cancel]}>
                        <IconContext.Provider value={{color: `${colors.white}`, size: "1.4rem", style: {fill: "white"}, className: "global-class-name"}}>
                            <FcCancel />
                        </IconContext.Provider>
                    </div>
                }
            />
            <span css={[fonts.button, label]}>Filter</span>
        </div>
    )
}