import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import HomePage from './pages/home_page'
import LocationPage from './pages/location_page'
import ReportPage from './pages/report_page'
import Menu from './components/menu'

function App() {
  return (
    <Router>
      <Route exact path="/">
        <HomePage />
      </Route>
      <Route exact path="/:placeID">
        <LocationPage />
      </Route>
      <Route exact path="/:placeID/:reportID" component={ReportPage} />
      {/* <Route exact path="/:placeID/:reportID">
        <ReportPage />
      </Route> */}

      <Menu />
    </Router>
  );
}

export default App;
